import '../assets/css/index.css';
import '../assets/scss/main.scss';

import { Outlet } from '@remix-run/react';
import { Provider as ReduxProvider } from 'react-redux';

import { useUserAnalytics } from '../analytics/user';
import { AdminAccess, RequireToken } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { useLoggerConsoleCtrl } from '../logger/logger-ctrl';
import { store } from '../store/configureStore';

function AdminShellRoutes() {
  useLoggerConsoleCtrl();

  const providers = [
    <ReduxProvider store={store} children={[]} />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <RequireToken loginType='password' />,
    <UserAccess />,
    <AdminAccess />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Outlet />
    </ProvidersList>
  );
}

export const Component = AdminShellRoutes;

export function clientLoader() {
  return null;
}
